import styled, { createGlobalStyle } from "styled-components";

const fonts = {
  dFont: "'Roboto', -apple-system, 'HelveticaNeue', sans-serif",
  hFont: "lato",
  pFont: "Roboto",
};

export const GlobalStyle = createGlobalStyle`
* {
box-sizing: border-box;
margin: 0;
padding: 0; 
font-family: ${fonts.dFont};
}
main{
  padding-top: 0rem;
  width: calc(100vw - 12rem);
  min-height: calc(100vh);
  margin: 1rem auto;
  padding-left: .5rem;
  padding-right: .5rem; 
  @media (max-width: 414px) {
  margin: 0rem;
  width:100%;
  padding:0;
  }
}
a {
  text-decoration: none;
  color: black;
}
p{
  font-family: ${fonts.pFont};
}
h1,h2,h3,h4,h5 {
  font-family: ${fonts.hFont};
  text-transform: uppercase;
  word-spacing: .5em;

} 
`;
export const DashboardLayout = styled.div`
  display: grid;
  grid-template-areas:
    "navbar navbar navbar navbar navbar navbar"
    "menu main main main main main"
    "footer footer footer footer footer footer"
    "copyright copyright copyright copyright copyright copyright";

  @media (max-width: 414px) {
    grid-template-areas:
      "navbar"
      "menu"
      "main"
      "footer"
      "copyright";
  }
`;

export const Section = styled.section`
  width: 100%;
  padding: 4rem 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const TableStyles = styled.div`
  padding: 1rem 1rem 1rem 0;
  /* width: fit-content; */
  width: 100%;
  input {
    height: 2.5rem;
    width: 100%;
    border: 2px solid lightgray;
    padding: 0.5rem 1rem;
    margin: 0 0 1.5rem 0;
    font-size: 1rem;
    ::placeholder {
      font-weight: lighter;
    }
  }
  table {
    /* width: 80%; */
    font-size: 0.84rem;
    border-spacing: 0;
    border: 1px solid lightgray;
    overflow: scroll;
    thead {
      color: white;
      background-color: #7c1ae6;
      th {
        font-weight: normal;
        letter-spacing: 1px;
        padding: 0.7rem 1rem;
      }
    }
    tbody {
      cursor: ${(props) => !props.extras && "pointer"};
      tr {
        :hover {
          background-color: whitesmoke;
        }
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      line-height: 1.2rem;
      margin: 0;
      width: 12rem;
      padding: 0.7rem 0.8rem 0.7rem 0.8rem;
      border-bottom: 1px solid lightgray;
      border-right: 1px solid lightgray;
      :first-child {
        /* width: 9rem; */
        width: ${(props) => (props.extras === true ? "5rem" : "fit-content")};
      }
      :nth-child(2) {
        width: ${(props) => (props.extras ? "10rem" : "fit-content")};
      }
      :nth-child(3) {
        width: ${(props) =>
          props.vp === true ? "9rem" : props.extras ? "fit-content" : "10rem"};
      }
      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 1.5rem;
    text-align: center;
    align-items: center;
    span {
      margin: 0 2rem;
      font-weight: lighter;
      strong {
        font-weight: lighter;
      }
    }
    button {
      cursor: pointer;
      margin: 0 1rem;
      padding: 0.5rem 0.7rem;
      color: black;
      background-color: whitesmoke;
      border: 1px solid lightgray;
    }
    input[type="number"] {
      font-size: 1rem;
      margin: 0 1rem;
      width: 4rem;
      height: 2rem;
      padding: 0.5rem;
    }
    select {
      background-color: whitesmoke;
      border: 1px solid lightgray;
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
      border: none;
      /* margin: 0 1rem; */
      width: 7rem;
      height: 2rem;
      padding: 0.5rem;
      option {
        background-color: whitesmoke;
      }
    }
  }
`;

export const Form = styled.form`
  margin: 3rem 0 0 0;
  display: grid;
  grid-template-columns: 1fr 8rem;
  grid-gap: 0rem;
  p {
    color: black;
    font-size: 0.9rem;
    padding-top: 1rem;
    line-height: 1.3rem;
  }
  /* div {
    display: flex;
    flex-wrap: wrap;
  } */
  label {
    height: fit-content;
    width: 11rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.8rem;
    font-size: 0.8rem;
    margin-right: 2rem;
    color: gray;
    margin-bottom: 1.5rem;
    div {
      color: black;
    }
  }

  select {
    font-size: 0.8rem;
    height: 2rem;
    box-sizing: border-box;
    border: none;
    background-color: white;
  }
  input,
  textarea {
    font-size: 0.9rem;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid lightsteelblue;
    height: 2.5rem;
    ::placeholder {
      color: gray;
      font-size: 0.9rem;
    }
  }
  textarea {
    padding-top: 0.7rem;
  }
  button {
    height: 4rem;
    background-color: green;
    color: white;
    border: none;
    padding: 0rem 1.5rem;
    margin: 2rem 0;
    font-size: 0.9rem;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
  canvas {
    height: 30rem;
    width: 30rem;
  }
`;
export const PaymentForm = styled.form`
  margin: 2rem 0 0 0;
  display: grid;
  grid-template-columns: 4fr 10rem;
  grid-gap: 0rem;

  label {
    height: fit-content;
    width: 12rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.8rem;
    font-size: 0.8rem;
    margin-right: 2rem;
    color: black;
    margin-bottom: 1.5rem;
  }
  select {
    font-size: 0.8rem;
    height: 2rem;
    box-sizing: border-box;
    border: none;
    background-color: white;
  }
  input {
    font-size: 0.9rem;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid lightsteelblue;
    height: 2.5rem;
  }
  button {
    height: 4rem;
    background-color: green;
    color: white;
    border: none;
    padding: 0rem 1.5rem;
    margin: 2rem 0;
    font-size: 0.9rem;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
`;

export const AddForm = styled(Form)`
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"] {
    width: 100%;
    font-size: 1rem;
  }
  label {
    margin-right: 3rem;
    ::placeholder {
      padding-bottom: 1rem;
      color: black;
    }
  }
`;
export const LoginForm = styled(Form)`
  display: flex;
  /* width: fit-content; */
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: 2rem 1rem 0 1rem;
  align-items: center;
  height: fit-content;
  label {
    width: 100%;
    height: 100%;
    font-size: 0.9rem;
    color: black;
    margin: auto;
    margin-bottom: 2rem;
    padding-bottom: 0rem;
  }
  input[type="email"],
  input[type="password"] {
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-top: 0.7rem;
    padding-left: 0.5rem;
    border: 1.5px solid #7c0ae2;
  }
  button {
    font-size: 1rem;
    margin: auto 0;
    height: 5.5rem;
    padding: 0.5rem 2rem;
  }
`;
export const PostsForm = styled(Form)`
  grid-template-columns: none;
  label {
    /* height: fit-content; */
    width: 6rem;
    display: flex;
    flex-direction: column;
    /* margin: 0.5rem 0; */
    margin-bottom: 0.5em;
    font-size: 0.6rem;
    margin-right: 0.5rem;
    color: gray;
    /* margin-bottom: 1.5rem; */
    div {
      color: black;
    }
    p {
      padding-top: 0.5rem;
      font-size: 0.7rem;
      width: 6rem;
    }
  }
`;
export const StatsCounterCard = styled.div`
  background-color: beige;
  color: black;
  padding: 1rem;
  margin: 2rem 0rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  h4 {
    padding-bottom: 1rem;
    border-bottom: 2px teal solid;
  }

  h2 {
    margin-top: 1rem;
    letter-spacing: 2px;
  }
`;

export const CanvasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 2px solid lightsteelblue;
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  align-items: center;
  /* background-color: ; */
  canvas,
  #TripType {
    width: 100%;
    height: 30%;
    margin-bottom: 2rem;
  }
  #Category {
    width: 50%;
    height: 70%;
  }
  #UserType,
  #OrderSource {
    max-width: 25%;
    max-height: 40%;
  }
`;
